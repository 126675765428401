import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistConfig, Persistor } from 'redux-persist/es/types';
import * as LocalForage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import rootReducer from './slices/root/root.reducer';
import { RootState } from './slices/root/root.state';

const createStoreConfig = (): { store: any; persistor: Persistor } => {
  const appSliceFilter = createBlacklistFilter('app', [
    'leadSource',
    'isCTAPopupVisible',
    'imageViewerParams',
    'referralCode',
  ]);

  const chatSliceFilter = createBlacklistFilter('chat', ['messagesQueue']);

  const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage: LocalForage,
    whitelist: ['app', 'chat'],
    stateReconciler: autoMergeLevel2,
    transforms: [appSliceFilter, chatSliceFilter],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default createStoreConfig;
