import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import useApiClient from './use-api-client';
import { User } from '../entities/user.entity';
import MeService from '../api/services/me.service';

export const ME_QUERY_KEY = 'me';

const useMeQuery = (
  options: UseQueryOptions<User> | undefined = undefined
): UseQueryResult<User> => {
  const apiClient = useApiClient();

  return useQuery<User>(ME_QUERY_KEY, () => new MeService(apiClient).getMe(), {
    staleTime: Infinity,
    ...options,
  });
};

export default useMeQuery;
