import React, { useEffect, useState } from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
// All external css files have to be located before _app.css
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-phone-number-input/style.css';
import '../styles/style.scss';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/sv';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/sv';
import Head from 'next/head';
import { NextPage } from 'next';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReduxToastr from 'react-redux-toastr';
import dynamic from 'next/dynamic';
import Cookies from 'js-cookie';
import nextI18NextConfig from '../next-i18next.config';
import createStoreConfig from '../model/create-store-config';
import AppContainer from '../components/app-container/app-container.component';
import { BROWSER_ID_COOKIE_NAME } from '../model/constants';
import getCookieAttributes from '../utils/get-cookie-attributes/get-cookie-attributes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [storeConfig] = useState<ReturnType<typeof createStoreConfig>>(createStoreConfig);

  const { t } = useTranslation();

  const getLayout = Component.getLayout || (page => page);

  const { webflowContent } = pageProps;

  useEffect(() => {
    if (typeof document !== 'undefined' && webflowContent != null) {
      const attributes: Record<string, string | null | undefined> = {
        'data-wf-domain': webflowContent.domain,
        'data-wf-page': webflowContent.page,
        'data-wf-site': webflowContent.site,
        'data-wf-status': '1',
      };
      Object.keys(attributes).forEach(key => {
        const value = attributes[key];
        if (value != null) {
          document.documentElement.setAttribute(key, value);
        }
      });
      if (webflowContent.bodyClass != null) {
        document.body.classList.add(...(webflowContent.bodyClass || '').split(' '));
      }
    }
  }, [webflowContent]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const browserId = Cookies.get(BROWSER_ID_COOKIE_NAME);
      if (!browserId) {
        Cookies.set(BROWSER_ID_COOKIE_NAME, Date.now().toString(), getCookieAttributes(365));
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={storeConfig.store}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/favicon/apple-touch-icon.png?v=1"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/img/favicon/favicon-32x32.png?v=1"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/img/favicon/favicon-16x16.png?v=1"
          />
          <link rel="manifest" href="/site.webmanifest?v=1" />
          <link rel="icon" href="/img/favicon/favicon.ico?v=1" />
          <meta name="apple-mobile-web-app-title" content="Ayd" />
          <meta name="application-name" content="Ayd" />
          <meta name="msapplication-TileColor" content="#de2938" />
          <meta name="msapplication-config" content="/browserconfig.xml?v=1" />
          <meta name="theme-color" content="#ffffff" />

          <title>{t('APP.META_DEFAULT.TITLE')}</title>
        </Head>
        <AppContainer>
          {getLayout(<Component {...pageProps} />)}
          <ReduxToastr
            timeOut={5200}
            newestOnTop
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
          <ReactTooltip multiline className="tooltip" />
        </AppContainer>
      </Provider>
    </QueryClientProvider>
  );
};

export default appWithTranslation(App, nextI18NextConfig);
