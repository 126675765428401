import {
  PurchaseType,
  TreatmentConfiguration,
} from '../../model/entities/patient-case-report.entity';
import { SubscriptionPreferences } from '../../model/entities/commerce/subscription.entity';

export enum PurchaseOptionType {
  OneTime = 'oneTime',
  Subscription = 'subscription',
  PrescriptionOnly = 'prescriptionOnly',
}

export interface OneTimePurchaseOption {
  type: PurchaseOptionType.OneTime;
}

export interface SubscriptionPurchaseOption {
  type: PurchaseOptionType.Subscription;
  subscriptionPreferences: SubscriptionPreferences;
}

export interface PrescriptionOnlyPurchaseOption {
  type: PurchaseOptionType.PrescriptionOnly;
}

export type PurchaseOption =
  | OneTimePurchaseOption
  | SubscriptionPurchaseOption
  | PrescriptionOnlyPurchaseOption;

const getPurchaseOption = (treatmentConfiguration: TreatmentConfiguration): PurchaseOption => {
  const { items } = treatmentConfiguration;

  const subscriptionItem = items.find(item => item.purchaseType === PurchaseType.Subscription);
  if (subscriptionItem && subscriptionItem.subscriptionPreferences) {
    return {
      type: PurchaseOptionType.Subscription,
      subscriptionPreferences: subscriptionItem.subscriptionPreferences,
    };
  }
  if (items.length === 1) {
    return {
      type: PurchaseOptionType.PrescriptionOnly,
    };
  }
  return {
    type: PurchaseOptionType.OneTime,
  };
};

export default getPurchaseOption;
