import { DiseaseCategory, FollowUpType } from './entities/patient-case-report.entity';
import { ProductCategory } from './entities/commerce/product.entity';
import { OrderStatus } from './entities/commerce/order.entity';
import { PurchaseOptionType } from '../utils/get-purchase-option/get-purchase-option';
import { WebflowPageItem } from '../utils/get-webflow-modified-link/get-webflow-modified-link';

export const DEFAULT_CURRENCY_CODE = 'SEK';

export const OTP_CODE_LENGTH = 6;

// Typeform ID for tests gCLS8yCz
export const FOLLOW_UP_WIDGETS: Record<
  string,
  Record<DiseaseCategory, Partial<Record<FollowUpType, string>>>
> = {
  en: {
    [DiseaseCategory.HairLoss]: {
      [FollowUpType.General]: 'zRbsTgas',
      [FollowUpType.Initial]: 'FaJ6bAvc',
    },
    [DiseaseCategory.Skincare]: {
      [FollowUpType.General]: 'zRbsTgas',
      [FollowUpType.Initial]: 'FaJ6bAvc',
    },
    [DiseaseCategory.ErectileDysfunction]: {
      [FollowUpType.General]: 'zRbsTgas',
      [FollowUpType.Initial]: 'FaJ6bAvc',
    },
    [DiseaseCategory.PrematureEjaculation]: {
      [FollowUpType.General]: 'zRbsTgas',
      [FollowUpType.Initial]: 'FaJ6bAvc',
    },
    [DiseaseCategory.WeightLoss]: {
      [FollowUpType.General]: 'FIFgi7WL',
      [FollowUpType.Initial]: 'PaGTVCo1',
      [FollowUpType.Calibration]: 'eduBGPay',
    },
  },
  sv: {
    [DiseaseCategory.HairLoss]: {
      [FollowUpType.General]: 'dxoF217z',
      [FollowUpType.Initial]: 'QBA29nDe',
    },
    [DiseaseCategory.Skincare]: {
      [FollowUpType.General]: 'dxoF217z',
      [FollowUpType.Initial]: 'QBA29nDe',
    },
    [DiseaseCategory.ErectileDysfunction]: {
      [FollowUpType.General]: 'dxoF217z',
      [FollowUpType.Initial]: 'QBA29nDe',
    },
    [DiseaseCategory.PrematureEjaculation]: {
      [FollowUpType.General]: 'dxoF217z',
      [FollowUpType.Initial]: 'QBA29nDe',
    },
    [DiseaseCategory.WeightLoss]: {
      [FollowUpType.General]: 'zuCOVmtB',
      [FollowUpType.Initial]: 'B98eOuVI',
      [FollowUpType.Calibration]: 'UqCEu4lq',
    },
  },
};

export const QUIZ_WIDGETS = {
  en: {
    [DiseaseCategory.HairLoss]: 'UvdjdEK7',
    [DiseaseCategory.Skincare]: 'GMIa6mgK',
    [DiseaseCategory.ErectileDysfunction]: 'sbCocytL',
    [DiseaseCategory.WeightLoss]: 'p6vu8v11',
    [DiseaseCategory.PrematureEjaculation]: 'S5I68p73',
  },
  sv: {
    [DiseaseCategory.HairLoss]: 'HffD5gSp',
    [DiseaseCategory.Skincare]: 'aPsKBgtd',
    [DiseaseCategory.ErectileDysfunction]: 'K7JaYgd2',
    [DiseaseCategory.WeightLoss]: 'Owy6Qz6p',
    [DiseaseCategory.PrematureEjaculation]: 'g5qlrVwc',
  },
};

export const SHORTHAND_CATEGORY_BY_DISEASE_CATEGORY: Record<DiseaseCategory, string> = {
  [DiseaseCategory.HairLoss]: 'hl',
  [DiseaseCategory.Skincare]: 'sc',
  [DiseaseCategory.ErectileDysfunction]: 'ed',
  [DiseaseCategory.WeightLoss]: 'wl',
  [DiseaseCategory.PrematureEjaculation]: 'pe',
};

interface OnboardingConfig {
  photoUploadRequired: boolean;
  allowedPurchaseOptions: PurchaseOptionType[];
}

export const ONBOARDING_CONFIGS: Record<DiseaseCategory, OnboardingConfig> = {
  [DiseaseCategory.HairLoss]: {
    photoUploadRequired: true,
    allowedPurchaseOptions: [
      PurchaseOptionType.Subscription,
      PurchaseOptionType.OneTime,
      PurchaseOptionType.PrescriptionOnly,
    ],
  },
  [DiseaseCategory.Skincare]: {
    photoUploadRequired: true,
    allowedPurchaseOptions: [PurchaseOptionType.PrescriptionOnly],
  },
  [DiseaseCategory.ErectileDysfunction]: {
    photoUploadRequired: false,
    allowedPurchaseOptions: [
      PurchaseOptionType.Subscription,
      PurchaseOptionType.OneTime,
      PurchaseOptionType.PrescriptionOnly,
    ],
  },
  [DiseaseCategory.PrematureEjaculation]: {
    photoUploadRequired: false,
    allowedPurchaseOptions: [
      PurchaseOptionType.Subscription,
      PurchaseOptionType.OneTime,
      PurchaseOptionType.PrescriptionOnly,
    ],
  },
  [DiseaseCategory.WeightLoss]: {
    photoUploadRequired: true,
    allowedPurchaseOptions: [PurchaseOptionType.Subscription],
  },
};

export const QUIZ_FILES: Record<string, Record<DiseaseCategory, string>> = {
  en: {
    [DiseaseCategory.Skincare]: 'Treatment Guidelines Acne.pdf',
    [DiseaseCategory.HairLoss]: 'Treatment Guidelines Hair Loss.pdf',
    [DiseaseCategory.ErectileDysfunction]: 'Treatment Guidelines ED.pdf',
    [DiseaseCategory.WeightLoss]: 'Treatment Guidelines Weight Loss.pdf',
    [DiseaseCategory.PrematureEjaculation]: 'Treatment Guidelines PE.pdf',
  },
  sv: {
    [DiseaseCategory.Skincare]: 'Behandlingsriktlinjer Akne.pdf',
    [DiseaseCategory.HairLoss]: 'Behandlingsriktlinjer Håravfall.pdf',
    [DiseaseCategory.ErectileDysfunction]: 'Behandlingsriktlinjer Erektionsbesvär.pdf',
    [DiseaseCategory.WeightLoss]: 'Behandlingsriktlinjer Viktnedgång.pdf',
    [DiseaseCategory.PrematureEjaculation]: 'Behandlingsriktlinjer PE.pdf',
  },
};

export const AYD_SUPPORT_EMAIL = 'support@ayd.se';

export const REVIEW_ON_TRUSTPILOT_LINK = 'https://se.trustpilot.com/evaluate/ayd.se';

export const PHARMACY_SUPPORT_EMAIL = 'hej@apoviva.se';

export const PHARMACY_SUPPORT_PHONE_NUMBER = '+46101676741';

export const INSTAGRAM_URL = 'https://www.instagram.com/getayd/';

export const FACEBOOK_URL = 'https://www.facebook.com/getAyd';

export const LINKEDIN_URL = 'https://www.linkedin.com/company/86192801/admin/';

export const NOTION_URL =
  'https://getayd.notion.site/Join-the-team-ebf11cca4a954251a49865e528f1497d';

export const CALENDLY_URL = 'https://calendly.com/getayd/ayd-viktnedgangstjanst';

export const WEBFLOW_PAGES: WebflowPageItem[] = [
  {
    webflowPath: '/',
    link: '/',
  },
  {
    webflowPath: '/article-light/404',
    link: '/404',
  },
  {
    webflowPath: '/landingpage/haravfall',
    link: '/hair-loss',
  },
  {
    webflowPath: '/landingpage/erektionsbesvar',
    link: '/erectile-dysfunction',
  },
  {
    webflowPath: '/landingpage/akne',
    link: '/skincare',
  },
  {
    webflowPath: '/weight-loss',
    link: '/weight-loss',
  },
  {
    webflowPath: '/premature-ejaculation',
    link: '/premature-ejaculation',
  },
  {
    webflowPath: '/ed-article',
    link: '/marketing-landing-pages/ed-article',
  },
  {
    webflowPath: '/landingpages-ads/impotens',
    link: '/marketing-landing-pages/impotence',
  },
  {
    webflowPath: '/erektions-besvar',
    link: '/marketing-landing-pages/ed-200224',
  },
  {
    webflowPath: '/the-weekend-pill',
    link: '/marketing-landing-pages/the-weekend-pill',
  },
  {
    webflowPath: '/erektions-lakemedel',
    link: '/marketing-landing-pages/erectile-dysfunction-medication',
  },
  {
    webflowPath: '/start-ed',
    link: '/marketing-landing-pages/start-ed',
  },
  {
    webflowPath: '/start-hl',
    link: '/marketing-landing-pages/start-hl',
  },
  {
    webflowPath: '/start-wl',
    link: '/marketing-landing-pages/start-wl',
  },
  {
    webflowPath: '/legacy',
    link: '/legacy-to-ecom',
  },
  {
    webflowPath: '/facts-advice',
    link: '/facts-and-advice',
  },
  {
    webflowPath: '/about',
    link: '/about',
  },
  {
    webflowPath: '/faq',
    link: '/faq',
  },
  {
    webflowPath: '/article-light/terms-and-conditions',
    link: '/terms-and-conditions',
  },
  {
    webflowPath: '/article-light/pengar-tillbaka-garanti',
    link: '/money-back-guarantee',
  },
  {
    webflowPath: '/article-light/privacy-policy',
    link: '/privacy-policy',
  },
  {
    webflowPath: '/article-light/cookie-policy',
    link: '/cookie-policy',
  },
  {
    webflowPath: '/article-light/kopvillkor',
    link: '/purchase-policy',
  },
  {
    webflowPath: '/apotek',
    link: '/pharmacy',
  },
  {
    webflowPath: '/intro-medical-hair',
    link: '/intro-medical-hair',
  },
  {
    webflowPath: '/intro-medical-ed',
    link: '/intro-medical-ed',
  },
  {
    webflowPath: '/intro-medical-pe',
    link: '/intro-medical-pe',
  },
  {
    webflowPath: '/intro-medical-wl',
    link: '/intro-medical-wl',
  },
  {
    webflowPath: '/intro-medical-skin',
    link: '/intro-medical-skin',
  },
  {
    webflowPath: '/intro-medical-general',
    link: '/intro-medical-general',
  },
  {
    webflowPath: '/meet-our-doctors',
    link: '/our-doctors',
  },
  {
    webflowPath: '/rabatt-start',
    link: '/referral-program',
  },
  {
    webflowPath: '/article-press',
    isFolder: true,
    link: '/press',
  },
  {
    webflowPath: '/article',
    isFolder: true,
    link: '/facts-and-advice',
  },
];

export const VERIFICATION_CODE_TIMEOUT = 3 * 1000;

export const PRODUCT_CATEGORY_BY_DISEASE_CATEGORY = {
  [DiseaseCategory.ErectileDysfunction]: ProductCategory.ErectileDysfunction,
  [DiseaseCategory.HairLoss]: ProductCategory.HairLoss,
  [DiseaseCategory.Skincare]: ProductCategory.Skincare,
  [DiseaseCategory.WeightLoss]: ProductCategory.WeightLoss,
  [DiseaseCategory.PrematureEjaculation]: ProductCategory.PrematureEjaculation,
};

export const PRESCRIPTION_ONLY_PRODUCT_SKU = 'PRESCRIPTION_ONLY';

export const SERVICE_FEE_PRODUCT_SKU = 'SERVICE_FEE';

export const ORDER_FINAL_STATUSES = [OrderStatus.Shipped, OrderStatus.Completed];

export const RESPONSIVE_MOBILE_MAX_WIDTH = 478;

export const IMAGE_UPLOAD_ACCEPTED_FILE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
];

// 1 is Monday
export const WORKING_HOURS = [
  {
    day: 1,
    start: '08:00',
    stop: '16:00',
  },
  {
    day: 2,
    start: '08:00',
    stop: '16:00',
  },
  {
    day: 3,
    start: '08:00',
    stop: '16:00',
  },
  {
    day: 4,
    start: '08:00',
    stop: '16:00',
  },
  {
    day: 5,
    start: '08:00',
    stop: '16:00',
  },
];

export const COOKIE_RESTORATION_BLACKLIST = ['AYD-CSRF-PROTECTION-TOKEN'];

export const REFERRAL_CODE_COOKIE_NAME = 'AYD-REFERRAL-CODE';
export const BROWSER_ID_COOKIE_NAME = 'AYD-BROWSER-ID';
export const LEAD_SOURCE_COOKIE_NAME = 'AYD-LEAD-SOURCE';
export const XSRF_COOKIE_NAME = 'AYD-CSRF-PROTECTION-TOKEN';

export const REFETCH_MESSAGES_INTERVAL_SECONDS = 15;

export const DEFAULT_COUNTRY_CODE = 'SE';
